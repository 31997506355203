const gapi = window.gapi;

const { REACT_APP_GOOGLE_API_KEY: googleApiKey } = process.env;

const discoveryDocs = [
  "https://sheets.googleapis.com/$discovery/rest?version=v4"
];

const loadApi = async () => {
  try {
    await new Promise((resolve) => {
      gapi.load("client", resolve);
    });

    await gapi.client.init({
      apiKey: googleApiKey,
      discoveryDocs
    });
  } catch (error) {
    console.warn("Error while loading Google APIs platform library.", error);
  }
};

const getSheet = async (payload: { spreadsheetId: string; range: string }) => {
  try {
    const {
      result: { values }
    } = await gapi.client.sheets.spreadsheets.values.get(payload);
    return values;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting sheet.", reason.result?.error.message);
  }
};

const googleApi = {
  loadApi,
  getSheet
};

export default googleApi;
