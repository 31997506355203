import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Key = styled.div<{ textStyle: string }>`
  ${(p) => p.textStyle && theme(`textStyle.${p.textStyle}`)};
  color: #969faf;
  background: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
  display: inline-block;
  align-items: center;
  border-radius: 3px;
  box-shadow: inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff,
    0 1px 2px 1px rgba(30, 35, 90, 0.4);
  justify-content: center;
  margin: 0 0.15em;
  height: 1.15em;
  width: 1.15em;
`;

export default Key;
