import React, { createContext, useContext } from "react";
import { ThemeProvider } from "styled-components";
import { HandlerSetter, useLocalStorage } from "beautiful-react-hooks";

import {
  createGlobalTheme,
  createTheme,
  createSubThemes,
  GlobalCSS
} from "@themes/utils";
import { THEMES } from "@constants";
import { useSystemTheme } from "@hooks";
import { Mediaquery, Theme } from "@types";

import { Normalize, Reset } from "./style";

const StyleContext = createContext({} as ProviderValue);

const StyleProvider = (props: ProviderProps) => {
  const { children } = props;
  const [localStorageTheme, setLocalStorageTheme] = useLocalStorage(
    "theme",
    THEMES.light
  );
  const systemTheme = useSystemTheme();

  // const availableThemes = [THEMES.light, THEMES.dark];
  // const isSystemTheme = !availableThemes.includes(localStorageTheme);
  // const theme = isSystemTheme ? THEMES[systemTheme] : localStorageTheme;
  const theme = THEMES[systemTheme];

  const globalTheme = createGlobalTheme();

  globalTheme.mediaquery.mediaqueries.forEach((mq: Mediaquery) => {
    globalTheme.breakpoints[mq.label] = mq.minWidth;
  });

  const activeTheme = {
    ...createTheme({ theme }),
    subthemes: {
      ...createSubThemes({ theme })
    }
  };

  if (activeTheme.mediaquery) {
    activeTheme.mediaquery.mediaqueries.forEach((mq: Mediaquery) => {
      activeTheme.breakpoints[mq.label] = mq.minWidth;
    });
  }

  return (
    <StyleContext.Provider
      value={{
        theme,
        localStorageTheme,
        setLocalStorageTheme,
        globalTheme
      }}
    >
      <ThemeProvider theme={{ ...globalTheme, ...activeTheme }}>
        <Reset />
        <Normalize />
        <GlobalCSS />
        {children}
      </ThemeProvider>
    </StyleContext.Provider>
  );
};

const useStyle = () => {
  return useContext(StyleContext);
};

const StyleConsumer = StyleContext.Consumer;

interface ProviderProps {
  children: JSX.Element;
}

interface ProviderValue {
  theme: string;
  localStorageTheme: string;
  setLocalStorageTheme: HandlerSetter<string>;
  globalTheme: Theme;
}

export { useStyle, StyleProvider, StyleConsumer, StyleContext };
