import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Tooltip = styled.div`
  position: relative;
  width: fit-content;
`;

const Tip = styled.div<{
  active: boolean;
  childrenWidth: number;
  fixOutOfBounds: number;
  bottom?: boolean;
}>`
  position: absolute;
  top: ${(p) => (p.bottom ? "auto" : `-${p.theme.spacing.s}`)};
  bottom: ${(p) => (p.bottom ? `-${p.theme.spacing.s}` : "auto")};
  border-radius: ${(p) => p.theme.radii.s};
  transform: translateX(
    calc(
      -50% + ${(p) => `${p.childrenWidth / 2}px`} - ${(p) => `${p.fixOutOfBounds}px`}
    )
  );
  padding: 3px;
  color: ${(p) => p.theme.colors.text01};
  background-color: rgba(125, 125, 125, 0.25);
  backdrop-filter: blur(20px);
  z-index: 1;
  white-space: nowrap;
  visibility: ${(p) => (p.active ? "visible" : "hidden")};
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity ${(p) => p.theme.timming.s};
  ${theme("textStyle.subtitle03")};
  pointer-events: none;
`;

export { Tooltip, Tip };
