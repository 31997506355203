import React, { FunctionComponent } from "react";
import { Stack } from "@sqymagma/elements";
import { useHistory } from "react-router";

import * as S from "./style";

const Button = (props: Props) => {
  const { href, to, action, icon, children, ...rest } = props;
  const { disabled = false, color } = rest;

  const history = useHistory();

  const handleClick = () => {
    action && action();
    to && history.push(to);
  };

  return (
    <S.StyledButton
      href={href}
      to={to}
      onClick={handleClick}
      as={href ? "a" : "button"}
      hasIcon={!!icon}
      {...rest}
    >
      <Stack alignItems="center" hs="xxxs">
        {children && (
          <S.Span disabled={disabled} color={color}>
            {children}
          </S.Span>
        )}
        {icon && (
          <S.IconPlace disabled={disabled} color={color}>
            {React.createElement(icon)}
          </S.IconPlace>
        )}
      </Stack>
    </S.StyledButton>
  );
};

interface Props {
  children: string;
  href?: string;
  to?: string;
  action?: () => void;
  icon?: FunctionComponent;
  disabled?: boolean;
  color?: string;
  appearance?: string;
}

export default Button;
