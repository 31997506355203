import React from "react";

import * as S from "./style";

const sizeParams = {
  default: {
    spinnerSize: 40,
    center: 20,
    radius: 18,
    strokeWidth: 4,
    strokeDashoffset: 105,
    animation: S.spinDefault
  },
  small: {
    spinnerSize: 20,
    center: 10,
    radius: 9,
    strokeWidth: 2,
    strokeDashoffset: 52.5,
    animation: S.spinSmall
  }
};

const Loader = (props: LoaderProps) => {
  const { small, light } = props;
  const size = small ? "small" : "default";

  return (
    <S.Spinner
      width={sizeParams[size].spinnerSize}
      height={sizeParams[size].spinnerSize}
      viewBox={`0 0 ${sizeParams[size].spinnerSize} ${sizeParams[size].spinnerSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <S.Circle
        cx={sizeParams[size].center}
        cy={sizeParams[size].center}
        r={sizeParams[size].radius}
        strokeWidth={sizeParams[size].strokeWidth}
        dashOffset={sizeParams[size].strokeDashoffset}
        animation={sizeParams[size].animation}
        light={light}
      />
    </S.Spinner>
  );
};

interface LoaderProps {
  small?: boolean;
  light?: boolean;
}

export default Loader;
