import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid ${(p) => p.theme.colors.line02};

  &:focus-within {
    border-bottom-color: ${(p) => p.theme.colors.brand01};
  }
`;

const Prefix = styled.div<{ small?: boolean }>`
  ${(p) => (p.small ? p.theme.textStyle.bodyInline : p.theme.textStyle.body)};
`;

const Input = styled.input<{ small?: boolean }>`
  ${(p) => (p.small ? p.theme.textStyle.bodyInline : p.theme.textStyle.body)}
  border: none;
  background: none;
  color: ${(p) => p.theme.colors.text01};
  padding: ${(p) => p.theme.spacing.xxxs} 0;
  width: 100%;

  &:focus {
    outline: 0;
    cursor: text;
  }
`;

export { Wrapper, Prefix, Input };
