import React from "react";
import { SelectOption } from "@types";

import * as S from "./style";

const Select = (props: SelectProps) => {
  const { value, options, onChange, placeholder, isSearchable = false } = props;

  const handleOnChange = (option: unknown) => {
    onChange(option as SelectOption);
  };

  const noOptionsMessage = () => "Sin opciones";

  return (
    <S.StyledSelect
      value={value}
      onChange={handleOnChange}
      options={options}
      isSearchable={isSearchable}
      isClearable
      classNamePrefix="react-select"
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

interface SelectProps {
  value: SelectOption;
  options: readonly SelectOption[];
  onChange: (option: SelectOption) => void;
  placeholder?: string;
  isSearchable?: boolean;
}

export default Select;
