import { Box, Button as MagmaButton } from "@sqymagma/elements";
import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const StyledButton = styled(MagmaButton)`
  min-width: ${(p) => p.theme.spacing.buttonM.minWidth};
  padding: ${(p) => p.theme.spacing.xxxs};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  border: none;
  pointer-events: ${(p) => (p.disabled ? "none" : "all")};
  transition: background-color ${(p) => p.theme.timming.hover};

  ${(p) => p.appearance === "secondary" && Secondary}

  &:focus {
    outline: none;
  }

  &:hover svg {
    ${(p) =>
      !p.disabled &&
      css`
        transform: ${(p) => `translateX(${p.theme.spacing.xxs})`};
      `}
  }

  &:hover span {
    ${(p) =>
      !p.hasIcon &&
      p.appearance === "primary" &&
      css`
        color: ${(p) => p.theme.colors.interactive01Hover};
      `}
  }

  svg {
    transition: transform 0.33s cubic-bezier(0.8, 0, 0.2, 1);
  }

  &:active {
    outline: none;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    color: ${theme("colors.textOnInteractive01Pressed")};
  }
`;

const IconPlace = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: ${theme("spacing.buttonS.iconSize")};
    height: ${theme("spacing.buttonS.iconSize")};
    fill: ${(p) =>
      p.color
        ? theme(`color.${p.color}`)
        : theme("colors.textOnInteractive01")};
    ${(p) =>
      p.disabled &&
      css`
        fill: ${theme("colors.disabled01")};
      `}
  }
`;

const Span = styled.span<{ disabled: boolean }>`
  ${(p) => p.theme.textStyle.button}
  color: ${(p) =>
    p.color ? theme(`color.${p.color}`) : theme("colors.textOnInteractive01")};
  ${(p) =>
    p.disabled &&
    css`
      color: ${theme("colors.disabled01")};
    `}
`;

const Secondary = css`
  background-color: ${theme("colors.secondaryBackground")};
  padding: ${(p) =>
    `${p.theme.spacing.buttonM.paddingY} ${p.theme.spacing.buttonM.paddingX}`};
  border-radius: ${(p) => p.theme.radii.s};

  &:hover {
    background-color: ${(p) => p.theme.colors.interactive01Hover};
    & ${Span} {
      color: ${theme("colors.light")};
    }
  }

  & ${Span} {
    color: ${theme("colors.text02")};
  }
`;

export { StyledButton, IconPlace, Span };
