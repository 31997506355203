import styled from "styled-components";

const Wrapper = styled.div``;

const Input = styled.input`
  display: none;

  &:checked + Label:after {
    left: 50%;
  }

  &:checked + Label {
    background: ${(p) => p.theme.colors.interactive01Active};
  }

  &:disabled + Label {
    pointer-events: none;
  }
`;

const Label = styled.label`
  box-sizing: border-box;
  outline: 0;
  display: block;
  width: 58px;
  height: 32px;
  position: relative;
  cursor: pointer;
  background: ${(p) => p.theme.colors.interactive01};
  border-radius: 2em;
  padding: 4px;
  transition: all 0.4s ease;

  &::selection {
    background: none;
  }

  &:after {
    border-radius: 50%;
    background: ${(p) => p.theme.colors.ui03};
    transition: all 0.2s ease;
  }

  &:after,
  &:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }

  &:after {
    left: 0;
  }

  &:before {
    display: none;
  }
`;

export { Wrapper, Input, Label };
