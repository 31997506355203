import React, { useCallback, useEffect, useState } from "react";
import { Text, Box, Flex, Columns, Column } from "@sqymagma/elements";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styled from "styled-components";

import googleApi from "@services/google";
import { Button, Loader, SearchBar, Select } from "@elements";
import { arrayToObject, getYearFromDate } from "@helpers";
import { useSearch } from "@hooks";
import { SelectOption, Taxonomy } from "@types";
import { Page } from "@components";

import Item from "./Item";

const getTaxonomiesFromYear = 2023;

const StyledText = styled(Text)`
  background: -webkit-linear-gradient(
    0deg,
    #ffa745,
    #fe869f 30%,
    #ef7ac8 45%,
    #a083ed 70%,
    #43aeff 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TaxonomyComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [taxonomies, setTaxonomies] = useSearch<Taxonomy>([], {
    searchQuery,
    searchFields: ["title", "description"]
  });
  const [yearFilter, setYearFilter] = useState<SelectOption>(null);
  const [numberOfTaxonomies, setNumberOfTaxonomies] = useState<number>();

  const loadData = async () => {
    await googleApi.loadApi();
    const { REACT_APP_TAXONOMY_GSHEET_ID: spreadsheetId } = process.env;
    if (!spreadsheetId) return;
    const payload = { spreadsheetId, range: "A2:E1000" };
    googleApi.getSheet(payload).then((rows) => {
      const keys = ["title", "description", "date", "url", "image"];
      const rowsObjects = rows?.map((row: string[]) =>
        arrayToObject<Taxonomy>(row, keys)
      );
      const lastTaxonomies = rowsObjects
        ?.filter((taxonomy) => {
          const year = getYearFromDate(taxonomy.date);
          return year >= getTaxonomiesFromYear;
        })
        .map((taxonomy) => ({
          ...taxonomy,
          title: /(^#\d+\s)?(.+)/.exec(taxonomy.title)?.[2] || "" // remove number from title
        }));
      if (lastTaxonomies) {
        setTaxonomies(lastTaxonomies.reverse());
        setNumberOfTaxonomies(lastTaxonomies.length);
      }
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYearFilter = (value: SelectOption) => {
    setYearFilter(value);
  };

  const yearOptions = useCallback(() => {
    const years = taxonomies.map((taxonomy) => getYearFromDate(taxonomy.date));
    return [...new Set(years)].map((year) => ({ value: year, label: year }));
  }, [taxonomies]);

  const filteredTaxonomies = taxonomies.filter((taxonomy) => {
    const year = getYearFromDate(taxonomy.date);
    return !yearFilter || year === yearFilter.value;
  });

  const noResults = !filteredTaxonomies.length && !!searchQuery.length;
  const showingMessage =
    numberOfTaxonomies &&
    `Mostrando ${filteredTaxonomies.length} de ${numberOfTaxonomies}`;

  const NoSearchResults = () => (
    <Text textStyle="body" color="text01">
      No se ha encontrado ninguna taxonomy en la búsqueda
    </Text>
  );

  const Loading = () => (
    <Flex justifyContent="center">
      <Loader />
    </Flex>
  );

  return (
    <Page>
      <Box textAlign="center">
        <StyledText textStyle="display01" color="text01" weight="bold">
          Taxonomy
        </StyledText>
      </Box>
      <Flex
        width={{ default: "100%", l: "70%", xl: "65%" }}
        mx="auto"
        flexDirection="column"
      >
        <Box mt="xs" mb="s">
          <Text
            textStyle="bodyLead"
            color="text01"
            textAlign="center"
            display="block"
          >
            Si te has perdido algún número de nuestra newsletter o quieres
            volver a echarle un vistazo a alguna, aquí puedes ver las que hemos
            enviado durante este año 2023.{" "}
            <strong>También puedes suscribirte y recibirla en tu email.</strong>
          </Text>
        </Box>
        <Flex justifyContent="center">
          <Button
            appearance="secondary"
            href="https://secuoyas.easymailing.com/app/public/list/suscribe/26d3e214909f4f04ba53f26d5b9f908d"
          >
            ¡Suscríbete!
          </Button>
        </Flex>
      </Flex>

      <Box mt="l">
        <Columns hs="s" vs="xxs" overflow="visible" alignItems="center">
          <Column width={[1, 1, 1 / 2, 1 / 2, 1 / 3]}>
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Busca una taxonomy"
            />
          </Column>
          <Column width={[1, 1, 1 / 2, 1 / 4, 1 / 5]}>
            <Select
              value={yearFilter}
              options={yearOptions()}
              onChange={handleYearFilter}
              placeholder="Todos los años"
            />
          </Column>
          <Column>
            {showingMessage && (
              <Text textStyle="bodyInline" color="text03">
                {showingMessage}
              </Text>
            )}
          </Column>
        </Columns>
      </Box>

      <Box mt="m">
        {filteredTaxonomies?.length ? (
          <ResponsiveMasonry>
            <Masonry gutter="20px">
              {filteredTaxonomies.map((taxonomy) => (
                <Item {...taxonomy} key={taxonomy.url} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        ) : noResults ? (
          <NoSearchResults />
        ) : (
          <Loading />
        )}
      </Box>
    </Page>
  );
};

export default TaxonomyComponent;
