import * as React from "react";
import { Box, Flex } from "@sqymagma/elements";
import { ReactComponent as Logo } from "@icons/logo.svg";
import { ReactComponent as LogoInverted } from "@icons/logo-inverted.svg";
import { useStyle } from "@contexts";
import { THEMES } from "@constants";

const Page = (props: { children: JSX.Element[] }) => {
  const { children } = props;

  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  return (
    <Box maxWidth="1250px" margin="auto" p="m" pb="l">
      <Flex width="100%" justifyContent="flex-end" mb="s">
        {isDarkTheme ? (
          <LogoInverted
            width="50px"
            onClick={() => window.open("https://secuoyas.com/", "_blank")}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Logo
            width="50px"
            onClick={() => window.open("https://secuoyas.com/", "_blank")}
            style={{ cursor: "pointer" }}
          />
        )}
      </Flex>
      {children}
    </Box>
  );
};

export default Page;
