import { format, isValid, parse, getYear } from "date-fns";

const es = require("date-fns/locale/es").default;

const areSameDay = (date1: Date | string, date2: Date | string) => {
  if (!isValid(new Date(date1)) || !isValid(new Date(date2))) return false;
  const d1 = format(new Date(date1), "y MM dd");
  const d2 = format(new Date(date2), "y MM dd");
  return d1 === d2;
};

const getMonthDates = (date: Date) => {
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59
  );
  return { firstDate, lastDate };
};

const getDayDates = (date: Date) => {
  const firstDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59
  );
  return { firstDate, lastDate };
};

const beautifyDate = (date: string) => {
  const parsedDate = parse(date, "d/M/yyyy", new Date());
  const beautyDate = isValid(parsedDate)
    ? format(parsedDate, "PPPP", { locale: es })
    : date;
  return beautyDate;
};

const getYearFromDate = (date: string) => {
  const parsedDate = parse(date, "d/M/yyyy", new Date());
  return getYear(parsedDate);
};

export {
  areSameDay,
  getMonthDates,
  getDayDates,
  beautifyDate,
  getYearFromDate
};
