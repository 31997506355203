// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeColsFromArr = (array: Array<any[]>, removeIndexes: number[]) => {
  return array.map((row) =>
    row.reduce(
      (prev, curr, index) =>
        removeIndexes.includes(index) ? prev : [...prev, curr],
      []
    )
  );
};

const arrayToObject = <Type>(array: string[], keys: string[]) => {
  return array.reduce(
    (prev, curr, index) => ({ ...prev, [keys[index]]: curr }),
    {} as Type
  );
};

export { removeColsFromArr, arrayToObject };
