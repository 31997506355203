import React from "react";
import "./index.css";

import { StyleProvider } from "@contexts";
import Taxonomy from "@screens";

const App = () => {
  return (
    <StyleProvider>
      <Taxonomy />
    </StyleProvider>
  );
};

export default App;
