import { theme } from "@sqymagma/theme";

const DOCUMENT_TITLE = "Secuoyas Taxonomy";

const CALENDAR_COLORS = {
  primary: theme("colors.calendar1"),
  secuoyas: theme("colors.calendar2"),
  holidays: theme("colors.holidays")
};

const WEEK_DAYS = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo"
];

const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;

const THEMES = {
  light: "default-theme",
  dark: "dark-theme",
  system: "system-theme"
};

export {
  DOCUMENT_TITLE,
  CALENDAR_COLORS,
  WEEK_DAYS,
  ONE_SECOND_IN_MS,
  ONE_MINUTE_IN_MS,
  THEMES
};
