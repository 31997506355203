import styled, { css } from "styled-components";

const wrapperSizes = { default: "34px", small: "20px", big: "44px" };
const iconSizes = { default: "22px", small: "100%", big: "28px" };

const IconWrapper = styled.div<{
  inverted?: boolean;
  squared?: boolean;
  rotated?: boolean;
  size: "default" | "small" | "big";
}>`
  background-color: ${(p) =>
    p.inverted
      ? p.theme.colors.quaternaryBackground
      : p.theme.colors.interactive02};
  padding: ${(p) => p.theme.spacing.xxxs};
  border-radius: ${(p) => (p.squared ? p.theme.radii.s : "50%")};
  width: ${(p) => wrapperSizes[p.size]};
  height: ${(p) => wrapperSizes[p.size]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color ${(p) => p.theme.timming.hover};

  ${(p) =>
    p.rotated &&
    css`
      transform: rotate(180deg);
    `}

  &:hover {
    background-color: ${(p) => p.theme.colors.interactive02Hover};
  }

  // rotate on click
  &.rotate {
    animation: rotate 1s;
  }

  & svg {
    fill: ${(p) =>
      p.inverted ? p.theme.colors.text01 : p.theme.colors.text02};
    width: ${(p) => iconSizes[p.size]};
    height: ${(p) => iconSizes[p.size]};
  }
`;

export { IconWrapper };
